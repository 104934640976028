// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {aGlt90Kfm: {hover: true}};

const cycleOrder = ["aGlt90Kfm"];

const variantClassNames = {aGlt90Kfm: "framer-v-ujbcm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "aGlt90Kfm", title: OBSn3slnf = "get in touch", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "aGlt90Kfm", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WkNpD", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-ujbcm", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aGlt90Kfm"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"aGlt90Kfm-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "R0Y7RmphbGxhIE9uZS1yZWd1bGFy", "--framer-font-family": "\"Fjalla One\", \"Fjalla One Placeholder\", sans-serif", "--framer-font-size": "100px", "--framer-letter-spacing": "-1.5px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-gdpscs)", "--framer-text-transform": "uppercase"}}>get in touch</motion.h1></React.Fragment>} className={"framer-12a5im5"} fonts={["GF;Fjalla One-regular"]} layoutDependency={layoutDependency} layoutId={"KLecxLn6d"} style={{"--extracted-gdpscs": "rgb(69, 69, 69)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={OBSn3slnf} transformTemplate={transformTemplate} transition={transition} variants={{"aGlt90Kfm-hover": {"--extracted-gdpscs": "rgb(186, 186, 186)"}}} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WkNpD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WkNpD * { box-sizing: border-box; }", ".framer-WkNpD .framer-mg9c65 { display: block; }", ".framer-WkNpD .framer-ujbcm { height: 120px; overflow: hidden; position: relative; width: 488px; }", ".framer-WkNpD .framer-12a5im5 { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-WkNpD .framer-v-ujbcm .framer-ujbcm { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 120
 * @framerIntrinsicWidth 488
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wvX3oc3Ks":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"OBSn3slnf":"title"}
 */
const FramerzHeNpzCuu: React.ComponentType<Props> = withCSS(Component, css, "framer-WkNpD") as typeof Component;
export default FramerzHeNpzCuu;

FramerzHeNpzCuu.displayName = "Link Title";

FramerzHeNpzCuu.defaultProps = {height: 120, width: 488};

addPropertyControls(FramerzHeNpzCuu, {OBSn3slnf: {defaultValue: "get in touch", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerzHeNpzCuu, [{family: "Fjalla One", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/zHeNpzCuu:default", url: "https://fonts.gstatic.com/s/fjallaone/v13/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/fjallaone/v13/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf", weight: "400"}])